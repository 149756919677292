/**
 * @file ngl
 * @private
 * @author Alexander Rose <alexander.rose@weirdbyte.de>
 */
import './polyfills';
import _Promise from 'promise-polyfill';
/**
 * The NGL module. These members are available in the `NGL` namespace when using the {@link https://github.com/umdjs/umd|UMD} build in the `ngl.js` file.
 * @module NGL
 */
export { Debug, setDebug, MeasurementDefaultParams, setMeasurementDefaultParams, ScriptExtensions, ColormakerRegistry, DecompressorRegistry, ParserRegistry, RepresentationRegistry } from './globals';
export { autoLoad, getDataInfo, getFileInfo } from './loader/loader-utils';
import Selection from './selection/selection';
import Stage from './stage/stage';
import Collection from './component/collection';
import ComponentCollection from './component/component-collection';
import Component from './component/component';
import ShapeComponent from './component/shape-component';
import StructureComponent from './component/structure-component';
import SurfaceComponent from './component/surface-component';
import VolumeComponent from './component/volume-component';
import RepresentationCollection from './component/representation-collection';
import Assembly from './symmetry/assembly';
import Superposition from './align/superposition';
export { superpose } from './align/align-utils';
export { guessElement, concatStructures } from './structure/structure-utils';
export { flatten, throttle, download, getQuery, uniqueArray } from './utils';
import Queue from './utils/queue';
import Counter from './utils/counter';
//
import Colormaker from './color/colormaker';
import './color/atomindex-colormaker';
import './color/bfactor-colormaker';
import './color/chainid-colormaker';
import './color/chainindex-colormaker';
import './color/chainname-colormaker';
import './color/densityfit-colormaker';
import './color/electrostatic-colormaker';
import './color/element-colormaker';
import './color/entityindex-colormaker';
import './color/entitytype-colormaker';
import './color/geoquality-colormaker';
import './color/hydrophobicity-colormaker';
import './color/modelindex-colormaker';
import './color/moleculetype-colormaker';
import './color/occupancy-colormaker';
import './color/partialcharge-colormaker';
import './color/random-colormaker';
import './color/randomcoilindex-colormaker';
import './color/residueindex-colormaker';
import './color/resname-colormaker';
import './color/sstruc-colormaker';
import './color/symmetry-colormaker';
import './color/uniform-colormaker';
import './color/value-colormaker';
import './color/volume-colormaker';
//
import './component/shape-component';
import './component/structure-component';
import './component/surface-component';
import './component/volume-component';
//
import './representation/angle-representation';
import './representation/axes-representation';
import './representation/backbone-representation';
import './representation/ballandstick-representation';
import './representation/polyhedra-representation';
import './representation/base-representation';
import './representation/contact-representation';
import './representation/dihedral-representation';
import './representation/dihedral-histogram-representation';
import './representation/distance-representation';
import './representation/helixorient-representation';
import './representation/label-representation';
import './representation/line-representation';
import './representation/point-representation';
import './representation/spacefill-representation';
import './representation/unitcell-representation';
import './representation/validation-representation';
import BufferRepresentation from './representation/buffer-representation';
import ArrowBuffer from './buffer/arrow-buffer';
import BoxBuffer from './buffer/box-buffer';
import ConeBuffer from './buffer/cone-buffer';
import CylinderBuffer from './buffer/cylinder-buffer';
import EllipsoidBuffer from './buffer/ellipsoid-buffer';
import MeshBuffer from './buffer/mesh-buffer';
import OctahedronBuffer from './buffer/octahedron-buffer';
import PointBuffer from './buffer/point-buffer';
import SphereBuffer from './buffer/sphere-buffer';
import TetrahedronBuffer from './buffer/tetrahedron-buffer';
import TextBuffer from './buffer/text-buffer';
import TorusBuffer from './buffer/torus-buffer';
import WidelineBuffer from './buffer/wideline-buffer';
import CylinderGeometryBuffer from './buffer/cylindergeometry-buffer';
//
import './parser/cif-parser';
import './parser/mol2-parser';
import './parser/pdb-parser';
import './parser/sdf-parser';
import './parser/obj-parser';
import './parser/ply-parser';
import './parser/csv-parser';
import './parser/json-parser';
import './parser/text-parser';
//
import Shape from './geometry/shape';
import Kdtree from './geometry/kdtree';
import SpatialHash from './geometry/spatial-hash';
import Structure from './structure/structure';
import MolecularSurface from './surface/molecular-surface';
import Volume from './surface/volume';
//
import './utils/gzip-decompressor';
//
export { LeftMouseButton, MiddleMouseButton, RightMouseButton } from './constants';
import MouseActions from './controls/mouse-actions';
import KeyActions from './controls/key-actions';
//
export { Signal } from 'signals';
export { Matrix3, Matrix4, Vector2, Vector3, Box3, Quaternion, Euler, Plane, Color } from 'three';
//
export { UIStageParameters } from './ui/parameters';
//
import Version from './version';
if (!window.Promise) {
    window.Promise = _Promise;
}
export { Version, Colormaker, Selection, Stage, Collection, ComponentCollection, RepresentationCollection, Component, ShapeComponent, StructureComponent, SurfaceComponent, VolumeComponent, Assembly, Superposition, Queue, Counter, BufferRepresentation, ArrowBuffer, BoxBuffer, ConeBuffer, CylinderBuffer, EllipsoidBuffer, MeshBuffer, OctahedronBuffer, PointBuffer, SphereBuffer, TetrahedronBuffer, TextBuffer, TorusBuffer, WidelineBuffer, CylinderGeometryBuffer, Shape, Structure, Kdtree, SpatialHash, MolecularSurface, Volume, MouseActions, KeyActions };
