/**
 * @file Animation
 * @author Alexander Rose <alexander.rose@weirdbyte.de>
 * @private
 */
import { Vector3, Quaternion } from 'three';
import { defaults, ensureVector3, ensureQuaternion } from '../utils';
import { lerp, smoothstep } from '../math/math-utils';
/**
 * Animation. Base animation class.
 * @interface
 */
class Animation {
    constructor(duration, controls, ...args) {
        this.pausedTime = -1;
        this.elapsedDuration = 0;
        this.pausedDuration = 0;
        this.ignoreGlobalToggle = false;
        this._paused = false;
        this._resolveList = [];
        this.duration = defaults(duration, 1000);
        this.controls = controls;
        this.startTime = window.performance.now();
        this._init(...args);
    }
    /**
     * True when animation has finished
     */
    get done() {
        return this.alpha === 1;
    }
    /**
     * True when animation is paused
     */
    get paused() {
        return this._paused;
    }
    tick(stats) {
        if (this._paused)
            return;
        this.elapsedDuration = stats.currentTime - this.startTime - this.pausedDuration;
        if (this.duration === 0) {
            this.alpha = 1;
        }
        else {
            this.alpha = smoothstep(0, 1, this.elapsedDuration / this.duration);
        }
        this._tick(stats);
        if (this.done) {
            this._resolveList.forEach(resolve => resolve());
        }
        return this.done;
    }
    /**
     * Pause animation
     * @param {boolean} [hold] - put animation on a hold which
     *                           must be release before it can be resumed
     */
    pause(hold) {
        if (hold)
            this._hold = true;
        if (this.pausedTime === -1) {
            this.pausedTime = window.performance.now();
        }
        this._paused = true;
    }
    /**
     * Resume animation
     * @param {Boolean} [releaseHold] - release a hold on the animation
     */
    resume(releaseHold) {
        if (!releaseHold && this._hold)
            return;
        this.pausedDuration += window.performance.now() - this.pausedTime;
        this._paused = false;
        this._hold = false;
        this.pausedTime = -1;
    }
    /**
     * Toggle animation
     */
    toggle() {
        if (this._paused) {
            this.resume();
        }
        else {
            this.pause();
        }
    }
    /**
     * Promise-like interface
     */
    then(callback) {
        let p;
        if (this.done) {
            p = Promise.resolve();
        }
        else {
            p = new Promise(resolve => this._resolveList.push(resolve));
        }
        return p.then(callback);
    }
}
export default Animation;
/**
 * Spin animation. Spin around an axis.
 */
export class SpinAnimation extends Animation {
    constructor(duration, controls, ...args) {
        super(defaults(duration, Infinity), controls, ...args);
    }
    _init(axis, angle) {
        if (Array.isArray(axis)) {
            this.axis = new Vector3().fromArray(axis);
        }
        else {
            this.axis = defaults(axis, new Vector3(0, 1, 0));
        }
        this.angle = defaults(angle, 0.01);
    }
    _tick(stats) {
        if (!this.axis || !this.angle)
            return;
        this.controls.spin(this.axis, this.angle * stats.lastDuration / 16);
    }
}
/**
 * Rock animation. Rock around an axis.
 */
export class RockAnimation extends Animation {
    constructor(duration, controls, ...args) {
        super(defaults(duration, Infinity), controls, ...args);
        this.angleSum = 0;
        this.direction = 1;
    }
    _init(axis, angleStep, angleEnd) {
        if (Array.isArray(axis)) {
            this.axis = new Vector3().fromArray(axis);
        }
        else {
            this.axis = defaults(axis, new Vector3(0, 1, 0));
        }
        this.angleStep = defaults(angleStep, 0.01);
        this.angleEnd = defaults(angleEnd, 0.2);
    }
    _tick(stats) {
        if (!this.axis || !this.angleStep || !this.angleEnd)
            return;
        const alpha = smoothstep(0, 1, Math.abs(this.angleSum) / this.angleEnd);
        const angle = this.angleStep * this.direction * (1.1 - alpha);
        this.controls.spin(this.axis, angle * stats.lastDuration / 16);
        this.angleSum += this.angleStep;
        if (this.angleSum >= this.angleEnd) {
            this.direction *= -1;
            this.angleSum = -this.angleEnd;
        }
    }
}
/**
 * Move animation. Move from one position to another.
 */
export class MoveAnimation extends Animation {
    _init(moveFrom, moveTo) {
        this.moveFrom = ensureVector3(defaults(moveFrom, new Vector3()));
        this.moveTo = ensureVector3(defaults(moveTo, new Vector3()));
    }
    _tick( /* stats */) {
        this.controls.position.lerpVectors(this.moveFrom, this.moveTo, this.alpha).negate();
        this.controls.changed();
    }
}
/**
 * Zoom animation. Gradually change the zoom level.
 */
export class ZoomAnimation extends Animation {
    _init(zoomFrom, zoomTo) {
        this.zoomFrom = zoomFrom;
        this.zoomTo = zoomTo;
    }
    _tick() {
        this.controls.distance(lerp(this.zoomFrom, this.zoomTo, this.alpha));
    }
}
/**
 * DHJ Symmetry Animations
 */
export class Point {
    constructor(x, y, z) {
        this.x = x;
        this.y = y;
        this.z = z;
    }
}
/**
 * Invert animation.
 */
export class SymInvertAnimation extends Animation {
    _init(compList, fr, to) {
        this.compList = compList;
        this.fixed = compList[0].structure;
        this.anim = compList[1].structure;
        this.fr = defaults(fr, 0);
        this.to = defaults(to, 1);
        this.range = this.to - this.fr;
    }
    _tick() {
        // console.log('InvertAnimation tick', this.alpha);
        let fa = this.fixed.atomStore;
        let aa = this.anim.atomStore;
        for (let j = 0; j < fa.length; j++) {
            aa.x[j] = fa.x[j] * (1 - 2 * (this.fr + this.alpha * this.range));
            aa.y[j] = fa.y[j] * (1 - 2 * (this.fr + this.alpha * this.range));
            aa.z[j] = fa.z[j] * (1 - 2 * (this.fr + this.alpha * this.range));
        }
        this.anim.updatePosition(this.anim.getAtomData({}).position);
        this.compList[1].updateRepresentations({ position: true });
        this.controls.changed();
    }
}
/**
 * Reset animation.
 */
export class SymResetAnimation extends Animation {
    _init(compList) {
        this.compList = compList;
        this.fixed = compList[0].structure;
        this.anim = compList[1].structure;
    }
    _tick() {
        let fa = this.fixed.atomStore;
        let aa = this.anim.atomStore;
        for (let j = 0; j < fa.length; j++) {
            aa.x[j] = fa.x[j];
            aa.y[j] = fa.y[j];
            aa.z[j] = fa.z[j];
        }
        this.anim.updatePosition(this.anim.getAtomData({}).position);
        this.compList[1].updateRepresentations({ position: true });
        this.controls.changed();
    }
}
/**
 * Proper Rotation animation.
 */
export class SymRotateAnimation extends Animation {
    _init(compList, axis, order, power, fr, to) {
        this.compList = compList;
        this.axis = axis;
        this.order = order;
        this.power = power;
        this.fixed = compList[0].structure;
        this.anim = compList[1].structure;
        this.fr = defaults(fr, 0);
        this.to = defaults(to, 1);
        this.range = this.to - this.fr;
        // console.log('Init RotateAnimation')
    }
    _tick() {
        // console.log('SymRotateAnimation tick', this.alpha);
        let newPoint = new Point(0, 0, 0);
        let angle = (2 * Math.PI * this.power) / this.order;
        let fa = this.fixed.atomStore;
        let aa = this.anim.atomStore;
        for (let j = 0; j < fa.length; j++) {
            let oldPoint = new Point(fa.x[j], fa.y[j], fa.z[j]);
            newPoint = arbitraryRotate(oldPoint, angle * (this.fr + this.alpha * this.range), this.axis);
            aa.x[j] = newPoint.x;
            aa.y[j] = newPoint.y;
            aa.z[j] = newPoint.z;
        }
        this.anim.updatePosition(this.anim.getAtomData({}).position);
        this.compList[1].updateRepresentations({ position: true });
        this.controls.changed();
    }
}
/**
 * Improper Rotation animation.
 */
export class SymImproperAnimation extends Animation {
    _init(compList, axis, order, power, part, fr, to) {
        this.compList = compList;
        this.axis = axis;
        this.order = order;
        this.power = power;
        this.part = part;
        this.fixed = compList[0].structure;
        this.anim = compList[1].structure;
        this.fr = defaults(fr, 0);
        this.to = defaults(to, 1);
        this.range = this.to - this.fr;
        // console.log('Init RotateAnimation')
    }
    _tick() {
        // console.log('SymRotateAnimation tick', this.alpha);
        let newPoint = new Point(0, 0, 0);
        let angle = (2 * Math.PI * this.power) / this.order;
        let fa = this.fixed.atomStore;
        let aa = this.anim.atomStore;
        // if ((this.fr + this.alpha * this.range) < 0.5) {
        if (this.part === 1) {
            for (let j = 0; j < fa.length; j++) {
                let oldPoint = new Point(fa.x[j], fa.y[j], fa.z[j]);
                newPoint = arbitraryRotate(oldPoint, angle * (this.fr + this.alpha * this.range), this.axis);
                aa.x[j] = newPoint.x;
                aa.y[j] = newPoint.y;
                aa.z[j] = newPoint.z;
            }
        }
        else {
            for (let j = 0; j < fa.length; j++) {
                let oldPoint = new Point(fa.x[j], fa.y[j], fa.z[j]);
                newPoint = arbitraryRotate(oldPoint, angle, this.axis);
                let distance = this.axis.x * newPoint.x + this.axis.y * newPoint.y + this.axis.z * newPoint.z;
                aa.x[j] = newPoint.x - (2.00 * distance * this.axis.x * (this.fr + this.alpha * this.range));
                aa.y[j] = newPoint.y - (2.00 * distance * this.axis.y * (this.fr + this.alpha * this.range));
                aa.z[j] = newPoint.z - (2.00 * distance * this.axis.z * (this.fr + this.alpha * this.range));
            }
        }
        this.anim.updatePosition(this.anim.getAtomData({}).position);
        this.compList[1].updateRepresentations({ position: true });
        this.controls.changed();
    }
}
/**
 * Improper Rotation animation (stepped).
 */
export class SymImproperAnimationStep extends Animation {
    _init(compList, axis, order, power, fr, to) {
        this.compList = compList;
        this.axis = axis;
        this.order = order;
        this.power = power;
        this.fixed = compList[0].structure;
        this.anim = compList[1].structure;
        this.fr = defaults(fr, 0);
        this.to = defaults(to, 1);
        this.range = this.to - this.fr;
    }
    _tick() {
        let newPoint = new Point(0, 0, 0);
        let angle = (2 * Math.PI * this.power) / this.order;
        let fa = this.fixed.atomStore;
        let aa = this.anim.atomStore;
        if ((this.fr + this.alpha * this.range) < 0.5) {
            for (let j = 0; j < fa.length; j++) {
                let oldPoint = new Point(fa.x[j], fa.y[j], fa.z[j]);
                newPoint = arbitraryRotate(oldPoint, 2 * angle * (this.fr + this.alpha * this.range), this.axis);
                aa.x[j] = newPoint.x;
                aa.y[j] = newPoint.y;
                aa.z[j] = newPoint.z;
            }
        }
        else {
            for (let j = 0; j < fa.length; j++) {
                let oldPoint = new Point(fa.x[j], fa.y[j], fa.z[j]);
                newPoint = arbitraryRotate(oldPoint, angle, this.axis);
                let distance = this.axis.x * newPoint.x + this.axis.y * newPoint.y + this.axis.z * newPoint.z;
                aa.x[j] = newPoint.x - (2.00 * distance * this.axis.x * 2 * ((this.fr + this.alpha * this.range) - 0.5));
                aa.y[j] = newPoint.y - (2.00 * distance * this.axis.y * 2 * ((this.fr + this.alpha * this.range) - 0.5));
                aa.z[j] = newPoint.z - (2.00 * distance * this.axis.z * 2 * ((this.fr + this.alpha * this.range) - 0.5));
            }
        }
        this.anim.updatePosition(this.anim.getAtomData({}).position);
        this.compList[1].updateRepresentations({ position: true });
        this.controls.changed();
    }
}
/**
 * Reflection animation.
 */
export class SymReflectAnimation extends Animation {
    _init(compList, normal, fr, to) {
        this.compList = compList;
        this.normal = normal;
        this.fixed = compList[0].structure;
        this.anim = compList[1].structure;
        this.fr = defaults(fr, 0);
        this.to = defaults(to, 1);
        this.range = this.to - this.fr;
        // console.log('Init RotateAnimation')
    }
    _tick() {
        // console.log('SymRotateAnimation tick', this.alpha);
        let fa = this.fixed.atomStore;
        let aa = this.anim.atomStore;
        for (let j = 0; j < fa.length; j++) {
            let distance = this.normal.x * fa.x[j] + this.normal.y * fa.y[j] + this.normal.z * fa.z[j];
            aa.x[j] = fa.x[j] - (2.00 * distance * this.normal.x * (this.fr + this.alpha * this.range));
            aa.y[j] = fa.y[j] - (2.00 * distance * this.normal.y * (this.fr + this.alpha * this.range));
            aa.z[j] = fa.z[j] - (2.00 * distance * this.normal.z * (this.fr + this.alpha * this.range));
        }
        this.anim.updatePosition(this.anim.getAtomData({}).position);
        this.compList[1].updateRepresentations({ position: true });
        this.controls.changed();
    }
}
/**
 * Symmetry Orient animation.
 */
export class SymOrientAnimation extends Animation {
    _init(qf, qt) {
        this.qfrom = qf;
        this.qto = qt;
    }
    _tick() {
        this.controls.changed();
    }
}
/**
 *  Utility function
 *  Rotate a point p by angle theta around an arbitrary axis vector
 *  Return the rotated point.
 *  Positive angles are anticlockwise looking down the axis
 *  towards the origin.
 *  Assume right hand coordinate system.
 */
function arbitraryRotate(p, theta, vector) {
    var q = new Point(0.0, 0.0, 0.0);
    var r = normalizeVector(vector);
    var costheta = Math.cos(theta);
    var sintheta = Math.sin(theta);
    q.x += (costheta + (1 - costheta) * r.x * r.x) * p.x;
    q.x += ((1 - costheta) * r.x * r.y - r.z * sintheta) * p.y;
    q.x += ((1 - costheta) * r.x * r.z + r.y * sintheta) * p.z;
    q.y += ((1 - costheta) * r.x * r.y + r.z * sintheta) * p.x;
    q.y += (costheta + (1 - costheta) * r.y * r.y) * p.y;
    q.y += ((1 - costheta) * r.y * r.z - r.x * sintheta) * p.z;
    q.z += ((1 - costheta) * r.x * r.z - r.y * sintheta) * p.x;
    q.z += ((1 - costheta) * r.y * r.z + r.x * sintheta) * p.y;
    q.z += (costheta + (1 - costheta) * r.z * r.z) * p.z;
    return q;
}
function normalizeVector(vector) {
    var ln = Math.sqrt(vector.x * vector.x + vector.y * vector.y + vector.z * vector.z);
    if (ln === 0) {
        return vector;
    } // do nothing for nothing
    vector.x = vector.x / ln;
    vector.y = vector.y / ln;
    vector.z = vector.z / ln;
    return vector;
}
/**
 * Rotate animation. Rotate from one orientation to another.
 */
export class RotateAnimation extends Animation {
    constructor() {
        super(...arguments);
        this._currentRotation = new Quaternion();
    }
    _init(rotateFrom, rotateTo) {
        this.rotateFrom = ensureQuaternion(rotateFrom);
        this.rotateTo = ensureQuaternion(rotateTo);
        this._currentRotation = new Quaternion();
    }
    _tick() {
        this._currentRotation
            .copy(this.rotateFrom)
            .slerp(this.rotateTo, this.alpha);
        this.controls.rotate(this._currentRotation);
    }
}
/**
 * Value animation. Call callback with interpolated value.
 */
export class ValueAnimation extends Animation {
    _init(valueFrom, valueTo, callback) {
        this.valueFrom = valueFrom;
        this.valueTo = valueTo;
        this.callback = callback;
    }
    _tick( /* stats */) {
        this.callback(lerp(this.valueFrom, this.valueTo, this.alpha));
    }
}
/**
 * Timeout animation. Call callback after duration.
 */
export class TimeoutAnimation extends Animation {
    _init(callback) {
        this.callback = callback;
    }
    _tick() {
        if (this.alpha === 1)
            this.callback();
    }
}
/**
 * Animation list.
 */
export class AnimationList {
    constructor(list = []) {
        this._resolveList = [];
        this._list = list;
    }
    /**
     * True when all animations have finished
     */
    get done() {
        return this._list.every(animation => {
            return animation.done;
        });
    }
    /**
     * Promise-like interface
     */
    then(callback) {
        let p;
        if (this.done) {
            p = Promise.resolve();
        }
        else {
            p = new Promise(resolve => {
                this._resolveList.push(resolve);
                this._list.forEach(animation => {
                    animation.then(() => {
                        this._resolveList.forEach(callback => {
                            callback();
                        });
                        this._resolveList.length = 0;
                    });
                });
            });
        }
        return p.then(callback);
    }
}
