/**
 * @file Box Buffer
 * @author Alexander Rose <alexander.rose@weirdbyte.de>
 * @private
 */
import { BoxBufferGeometry, Vector3 } from 'three';
import { BufferRegistry } from '../globals';
import GeometryBuffer from './geometry-buffer';
const scale = new Vector3();
const target = new Vector3();
const up = new Vector3();
const eye = new Vector3(0, 0, 0);
/**
 * Box buffer. Draws boxes.
 *
 * @example
 * var boxBuffer = new BoxBuffer({
 *   position: new Float32Array([ 0, 3, 0, -2, 0, 0 ]),
 *   color: new Float32Array([ 1, 0, 1, 0, 1, 0 ]),
 *   size: new Float32Array([ 2, 1.5 ]),
 *   heightAxis: new Float32Array([ 0, 1, 1, 0, 2, 0 ]),
 *   depthAxis: new Float32Array([ 1, 0, 1, 0, 0, 2 ])
 * })
 */
class BoxBuffer extends GeometryBuffer {
    constructor(data, params = {}) {
        super(data, params, new BoxBufferGeometry(1, 1, 1));
        this.updateNormals = true;
        this.setAttributes(data, true);
    }
    applyPositionTransform(matrix, i, i3) {
        target.fromArray(this._heightAxis, i3);
        up.fromArray(this._depthAxis, i3);
        matrix.lookAt(eye, target, up);
        scale.set(this._size[i], up.length(), target.length());
        matrix.scale(scale);
    }
    setAttributes(data = {}, initNormals) {
        if (data.size)
            this._size = data.size;
        if (data.heightAxis)
            this._heightAxis = data.heightAxis;
        if (data.depthAxis)
            this._depthAxis = data.depthAxis;
        super.setAttributes(data, initNormals);
    }
}
BufferRegistry.add('box', BoxBuffer);
export default BoxBuffer;
