/**
 * @file Face Proxy
 * @author Alexander Rose <alexander.rose@weirdbyte.de>
 * @private
 */
/**
 * Face proxy
 */
class FaceProxy {
    /**
     * @param {Structure} structure - the structure
     * @param {Integer} index - the index
     */
    constructor(structure, index = 0) {
        this.structure = structure;
        this.index = index;
        this.faceStore = structure.faceStore;
    }
    /**
     * @type {AtomProxy}
     */
    get atom1() {
        return this.structure.getAtomProxy(this.atomIndex1);
    }
    /**
     * @type {AtomProxy}
     */
    get atom2() {
        return this.structure.getAtomProxy(this.atomIndex2);
    }
    /**
     * @type {AtomProxy}
     */
    get atom3() {
        return this.structure.getAtomProxy(this.atomIndex3);
    }
    /**
     * @type {AtomProxy}
     */
    get atom4() {
        return this.structure.getAtomProxy(this.atomIndex4);
    }
    /**
     * @type {AtomProxy}
     */
    get atom5() {
        return this.structure.getAtomProxy(this.atomIndex5);
    }
    /**
     * @type {AtomProxy}
     */
    get atom6() {
        return this.structure.getAtomProxy(this.atomIndex6);
    }
    /**
     * @type {Integer}
     */
    get atomIndex1() {
        return this.faceStore.atomIndex1[this.index];
    }
    set atomIndex1(value) {
        this.faceStore.atomIndex1[this.index] = value;
    }
    /**
     * @type {Integer}
     */
    get atomIndex2() {
        return this.faceStore.atomIndex2[this.index];
    }
    set atomIndex2(value) {
        this.faceStore.atomIndex2[this.index] = value;
    }
    /**
     * @type {Integer}
     */
    get atomIndex3() {
        return this.faceStore.atomIndex3[this.index];
    }
    set atomIndex3(value) {
        this.faceStore.atomIndex3[this.index] = value;
    }
    /**
     * @type {Integer}
     */
    get atomIndex4() {
        return this.faceStore.atomIndex4[this.index];
    }
    set atomIndex4(value) {
        this.faceStore.atomIndex4[this.index] = value;
    }
    /**
     * @type {Integer}
     */
    get atomIndex5() {
        return this.faceStore.atomIndex5[this.index];
    }
    set atomIndex5(value) {
        this.faceStore.atomIndex5[this.index] = value;
    }
    /**
     * @type {Integer}
     */
    get atomIndex6() {
        return this.faceStore.atomIndex6[this.index];
    }
    set atomIndex6(value) {
        this.faceStore.atomIndex6[this.index] = value;
    }
    /**
     * @type {Integer}
     */
    get faceOrder() {
        return this.faceStore.faceOrder[this.index];
    }
    set faceOrder(value) {
        this.faceStore.faceOrder[this.index] = value;
    }
    /**
     * @type {Integer}
     */
    get faceColor() {
        return this.faceStore.faceColor[this.index];
    }
    set faceColor(value) {
        this.faceStore.faceColor[this.index] = value;
    }
    qualifiedName() {
        return this.atomIndex1 + '=' + this.atomIndex2;
    }
    /**
     * Clone object
     * @return {FaceProxy} cloned bond
     */
    clone() {
        return new FaceProxy(this.structure, this.index);
    }
    toObject() {
        return {
            faceOrder: this.faceOrder,
            faceColor: this.faceColor,
            atomIndex1: this.atomIndex1,
            atomIndex2: this.atomIndex2,
            atomIndex3: this.atomIndex3,
            atomIndex4: this.atomIndex4,
            atomIndex5: this.atomIndex5,
            atomIndex6: this.atomIndex6
        };
    }
}
export default FaceProxy;
