/**
 * @file Animation Controls
 * @author Alexander Rose <alexander.rose@weirdbyte.de>
 * @private
 */
import { Vector3, Quaternion } from 'three';
import { ensureMatrix4 } from '../utils';
import { SpinAnimation, RockAnimation, MoveAnimation, ZoomAnimation, SymInvertAnimation, SymResetAnimation, SymRotateAnimation, SymReflectAnimation, SymImproperAnimation, SymImproperAnimationStep, RotateAnimation, ValueAnimation, TimeoutAnimation, AnimationList } from '../animation/animation';
/**
 * Animation controls
 */
class AnimationControls {
    /**
     * Create animation controls
     * @param  {Stage} stage - the stage object
     */
    constructor(stage) {
        this.stage = stage;
        this.animationList = [];
        this.finishedList = [];
        this.viewer = stage.viewer;
        this.controls = stage.viewerControls;
    }
    /**
     * True when all animations are paused
     * @type {Boolean}
     */
    get paused() {
        return this.animationList.every((animation) => animation.paused);
    }
    /**
     * Add an animation
     */
    add(animation) {
        if (animation.duration === 0) {
            animation.tick(this.viewer.stats);
        }
        else {
            this.animationList.push(animation);
        }
        return animation;
    }
    /**
     * Remove an animation
     */
    remove(animation) {
        const list = this.animationList;
        const index = list.indexOf(animation);
        if (index > -1) {
            list.splice(index, 1);
        }
    }
    /**
     * Run all animations
     */
    run(stats) {
        const finishedList = this.finishedList;
        const animationList = this.animationList;
        const n = animationList.length;
        for (let i = 0; i < n; ++i) {
            const animation = animationList[i];
            // tick returns true when finished
            if (animation.tick(stats)) {
                finishedList.push(animation);
            }
        }
        const m = finishedList.length;
        if (m) {
            for (let j = 0; j < m; ++j) {
                this.remove(finishedList[j]);
            }
            finishedList.length = 0;
        }
    }
    /**
     * Add a spin animation
     * @param  {Vector3} axis - axis to spin around
     * @param  {Number} angle - amount to spin per frame, radians
     * @param  {Number} duration - animation time in milliseconds
     * @return {SpinAnimation} the animation
     */
    spin(axis, angle, duration) {
        return this.add(new SpinAnimation(duration, this.controls, axis, angle));
    }
    /**
     * Add a rock animation
     * @param  {Vector3} axis - axis to rock around
     * @param  {Number} angle - amount to spin per frame, radians
     * @param  {Number} end - maximum extend of motion, radians
     * @param  {Number} duration - animation time in milliseconds
     * @return {SpinAnimation} the animation
     */
    rock(axis, angle, end, duration) {
        return this.add(new RockAnimation(duration, this.controls, axis, angle, end));
    }
    /**
     * Add a rotate animation
     * @param  {Quaternion} rotateTo - target rotation
     * @param  {Number} duration - animation time in milliseconds
     * @return {RotateAnimation} the animation
     */
    rotate(rotateTo, duration) {
        const rotateFrom = this.viewer.rotationGroup.quaternion.clone();
        return this.add(new RotateAnimation(duration, this.controls, rotateFrom, rotateTo));
    }
    /**
     * Add a move animation
     * @param  {Vector3} moveTo - target position
     * @param  {Number} duration - animation time in milliseconds
     * @return {MoveAnimation} the animation
     */
    move(moveTo, duration) {
        const moveFrom = this.controls.position.clone().negate();
        return this.add(new MoveAnimation(duration, this.controls, moveFrom, moveTo));
    }
    /**
     * Add a zoom animation
     * @param  {Number} zoomTo - target distance
     * @param  {Number} duration - animation time in milliseconds
     * @return {ZoomAnimation} the animation
     */
    zoom(zoomTo, duration) {
        const zoomFrom = this.viewer.camera.position.z;
        return this.add(new ZoomAnimation(duration, this.controls, zoomFrom, zoomTo));
    }
    /**
     * Add an inversion symmetry animation
     * @param  {ComponentList} c - array of components (Structures)
     * @param  {Number} duration - animation time in milliseconds
     * @return {SymInvertAnimation} the animation
     */
    symInvert(c, duration, fr, to) {
        return this.add(new SymInvertAnimation(duration, this.controls, c, fr, to));
    }
    /**
     * Reset atom positions
     * @param  {ComponentList} c - array of components (Structures)
     * @param  {Number} duration - animation time in milliseconds
     * @return {SymResetAnimation} the animation
     */
    symReset(c, duration) {
        return this.add(new SymResetAnimation(duration, this.controls, c));
    }
    /**
     * Add a proper rotation symmetry animation
     * @param  {ComponentList} c - array of components (Structures)
     * @param  {Number} duration - animation time in milliseconds
     * @param  {Point} axis - axis of rotation
     * @param  {Number} order - order of axis
     * @param  {Number} power - power of operation
     * @return {SymRotateAnimation} the animation
     */
    symRotate(c, axis, order, power, duration, fr, to) {
        return this.add(new SymRotateAnimation(duration, this.controls, c, axis, order, power, fr, to));
    }
    /**
     * Add a reflection symmetry animation
     * @param  {ComponentList} c - array of components (Structures)
     * @param  {Number} duration - animation time in milliseconds
     * @param  {Point} axis - axis perpendicular to plane
     * @return {SymReflectAnimation} the animation
     */
    symReflect(c, axis, duration, fr, to) {
        return this.add(new SymReflectAnimation(duration, this.controls, c, axis, fr, to));
    }
    /**
     * Add an improper rotation symmetry animation
     * @param  {ComponentList} c - array of components (Structures)
     * @param  {Number} duration - animation time in milliseconds
     * @param  {Point} axis - axis of rotation
     * @param  {Number} order - order of axis
     * @param  {Number} power - power of operation
     * @return {SymImproperAnimation} the animation
     */
    symImproper(c, axis, order, power, duration, fr, to) {
        const anim1 = new SymImproperAnimation(duration, this.controls, c, axis, order, power, 1, fr, to);
        anim1.then(() => { this.symImproper1(c, axis, order, power, duration, fr, to); });
        return this.add(anim1);
    }
    symImproper1(c, axis, order, power, duration, fr, to) {
        return this.add(new SymImproperAnimation(duration, this.controls, c, axis, order, power, 2, fr, to));
    }
    symImproperStep(c, axis, order, power, duration, fr, to) {
        return this.add(new SymImproperAnimationStep(duration, this.controls, c, axis, order, power, fr, to));
    }
    /**
     * Add an symmetry element orient animation
     * @param  {PointGroupOperation} op - operation / element to align
     * @param  {Number} duration - animation time in milliseconds
     * @return {Array} the animations
     */
    symOrient(op, duration) {
        const p = new Vector3();
        const q = new Quaternion();
        p.x = op.direction.x;
        p.y = op.direction.y + 0.001; // add slight misalignment to
        p.z = op.direction.z + 0.001; // avoid disappearing planes
        p.normalize();
        if ((op.etype === 'mirror') || (op.etype == 'improp')) {
            q.setFromUnitVectors(p, new Vector3(1, 0, 0));
        }
        else {
            q.setFromUnitVectors(p, new Vector3(0, 0, -1));
        }
        return this.add(this.rotate(q, duration));
    }
    /**
     * Add a zoom and a move animation
     * @param  {Vector3} moveTo - target position
     * @param  {Number} zoomTo - target distance
     * @param  {Number} duration - animation time in milliseconds
     * @return {Array} the animations
     */
    zoomMove(moveTo, zoomTo, duration) {
        return new AnimationList([
            this.move(moveTo, duration),
            this.zoom(zoomTo, duration)
        ]);
    }
    /**
     * Add an orient animation
     * @param  {OrientationMatrix|Array} orientTo - target orientation
     * @param  {Number} duration - animation time in milliseconds
     * @return {Array} the animations
     */
    orient(orientTo, duration) {
        const p = new Vector3();
        const q = new Quaternion();
        const s = new Vector3();
        ensureMatrix4(orientTo).decompose(p, q, s);
        return new AnimationList([
            this.move(p.negate(), duration),
            this.rotate(q, duration),
            this.zoom(-s.x, duration)
        ]);
    }
    /**
     * Add a value animation
     * @param  {Number} valueFrom - start value
     * @param  {Number} valueTo - target value
     * @param  {Function} callback - called on every tick
     * @param  {Number} duration - animation time in milliseconds
     * @return {ValueAnimation} the animation
     */
    value(valueFrom, valueTo, callback, duration) {
        return this.add(new ValueAnimation(duration, this.controls, valueFrom, valueTo, callback));
    }
    /**
     * Add a timeout animation
     * @param  {Function} callback - called after duration
     * @param  {Number} duration - timeout in milliseconds
     * @return {TimeoutAnimation} the animation
     */
    timeout(callback, duration) {
        return this.add(new TimeoutAnimation(duration, this.controls, callback));
    }
    /**
     * Add a component spin animation
     * @param  {Component} component - object to move
     * @param  {Vector3} axis - axis to spin around
     * @param  {Number} angle - amount to spin per frame, radians
     * @param  {Number} duration - animation time in milliseconds
     * @return {SpinAnimation} the animation
     */
    spinComponent(component, axis, angle, duration) {
        return this.add(
        // TODO
        new SpinAnimation(duration, component.controls, axis, angle));
    }
    /**
     * Add a component rock animation
     * @param  {Component} component - object to move
     * @param  {Vector3} axis - axis to rock around
     * @param  {Number} angle - amount to spin per frame, radians
     * @param  {Number} end - maximum extend of motion, radians
     * @param  {Number} duration - animation time in milliseconds
     * @return {SpinAnimation} the animation
     */
    rockComponent(component, axis, angle, end, duration) {
        return this.add(
        // TODO
        new RockAnimation(duration, component.controls, axis, angle, end));
    }
    /**
     * Add a component move animation
     * @param  {Component} component - object to move
     * @param  {Vector3} moveTo - target position
     * @param  {Number} duration - animation time in milliseconds
     * @return {MoveAnimation} the animation
     */
    moveComponent(component, moveTo, duration) {
        const moveFrom = component.controls.position.clone().negate();
        return this.add(
        // TODO
        new MoveAnimation(duration, component.controls, moveFrom, moveTo));
    }
    /**
     * Pause all animations
     * @return {undefined}
     */
    pause() {
        this.animationList.forEach(animation => animation.pause());
    }
    /**
     * Resume all animations
     * @return {undefined}
     */
    resume() {
        this.animationList.forEach(animation => animation.resume());
    }
    /**
     * Toggle all animations
     * @return {undefined}
     */
    toggle() {
        if (this.paused) {
            this.resume();
        }
        else {
            this.pause();
        }
    }
    /**
     * Clear all animations
     * @return {undefined}
     */
    clear() {
        this.animationList.length = 0;
    }
    dispose() {
        this.clear();
    }
}
export default AnimationControls;
