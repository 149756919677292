/**
 * @file Utils
 * @author Alexander Rose <alexander.rose@weirdbyte.de>
 * @private
 */
import { Vector2, Vector3, Matrix4, Quaternion } from 'three';
export function getQuery(id) {
    if (typeof window === 'undefined')
        return undefined;
    const a = new RegExp(`${id}=([^&#=]*)`);
    const m = a.exec(window.location.search);
    if (m) {
        return decodeURIComponent(m[1]);
    }
    else {
        return undefined;
    }
}
export function boolean(value) {
    if (!value) {
        return false;
    }
    if (typeof value === 'string') {
        return /^1|true|t|yes|y$/i.test(value);
    }
    return true;
}
export function defaults(value, defaultValue) {
    return value !== undefined ? value : defaultValue;
}
export function createParams(params, defaultParams) {
    const o = Object.assign({}, params);
    for (const k in defaultParams) {
        const value = params[k];
        if (value === undefined)
            o[k] = defaultParams[k];
    }
    return o;
}
export function updateParams(params, newParams) {
    for (const k in newParams) {
        const value = newParams[k];
        if (value !== undefined)
            params[k] = value;
    }
    return params;
}
export function pick(object) {
    const properties = [].slice.call(arguments, 1);
    return properties.reduce((a, e) => {
        a[e] = object[e];
        return a;
    }, {});
}
export function flatten(array, ret) {
    ret = defaults(ret, []);
    for (let i = 0; i < array.length; i++) {
        if (Array.isArray(array[i])) {
            flatten(array[i], ret);
        }
        else {
            ret.push(array[i]);
        }
    }
    return ret;
}
export function getProtocol() {
    const protocol = window.location.protocol;
    return protocol.match(/http(s)?:/gi) === null ? 'http:' : protocol;
}
export function getBrowser() {
    if (typeof window === 'undefined')
        return false;
    const ua = window.navigator.userAgent;
    if (/Opera|OPR/.test(ua)) {
        return 'Opera';
    }
    else if (/Chrome/i.test(ua)) {
        return 'Chrome';
    }
    else if (/Firefox/i.test(ua)) {
        return 'Firefox';
    }
    else if (/Mobile(\/.*)? Safari/i.test(ua)) {
        return 'Mobile Safari';
    }
    else if (/MSIE/i.test(ua)) {
        return 'Internet Explorer';
    }
    else if (/Safari/i.test(ua)) {
        return 'Safari';
    }
    return false;
}
export function getAbsolutePath(relativePath) {
    const loc = window.location;
    const pn = loc.pathname;
    const basePath = pn.substring(0, pn.lastIndexOf('/') + 1);
    return loc.origin + basePath + relativePath;
}
export function deepCopy(src) {
    if (typeof src !== 'object') {
        return src;
    }
    const dst = Array.isArray(src) ? [] : {};
    for (let key in src) {
        dst[key] = deepCopy(src[key]);
    }
    return dst;
}
export function deepEqual(a, b) {
    // from https://github.com/epoberezkin/fast-deep-equal MIT
    if (a === b)
        return true;
    const arrA = Array.isArray(a);
    const arrB = Array.isArray(b);
    if (arrA && arrB) {
        if (a.length !== b.length)
            return false;
        for (let i = 0; i < a.length; i++) {
            if (!deepEqual(a[i], b[i]))
                return false;
        }
        return true;
    }
    if (arrA !== arrB)
        return false;
    if (a && b && typeof a === 'object' && typeof b === 'object') {
        const keys = Object.keys(a);
        if (keys.length !== Object.keys(b).length)
            return false;
        const dateA = a instanceof Date;
        const dateB = b instanceof Date;
        if (dateA && dateB)
            return a.getTime() === b.getTime();
        if (dateA !== dateB)
            return false;
        const regexpA = a instanceof RegExp;
        const regexpB = b instanceof RegExp;
        if (regexpA && regexpB)
            return a.toString() === b.toString();
        if (regexpA !== regexpB)
            return false;
        for (let i = 0; i < keys.length; i++) {
            if (!Object.prototype.hasOwnProperty.call(b, keys[i]))
                return false;
        }
        for (let i = 0; i < keys.length; i++) {
            if (!deepEqual(a[keys[i]], b[keys[i]]))
                return false;
        }
        return true;
    }
    return false;
}
function openUrl(url) {
    const opened = window.open(url, '_blank');
    if (!opened) {
        window.location.href = url;
    }
}
export function download(data, downloadName = 'download') {
    // using ideas from https://github.com/eligrey/FileSaver.js/blob/master/FileSaver.js
    if (!data)
        return;
    const isSafari = getBrowser() === 'Safari';
    const isChromeIos = /CriOS\/[\d]+/.test(window.navigator.userAgent);
    const a = document.createElement('a');
    function open(str) {
        openUrl(isChromeIos ? str : str.replace(/^data:[^;]*;/, 'data:attachment/file;'));
    }
    if (typeof navigator !== 'undefined' && navigator.msSaveOrOpenBlob) {
        // native saveAs in IE 10+
        navigator.msSaveOrOpenBlob(data, downloadName);
    }
    else if ((isSafari || isChromeIos) && FileReader) {
        if (data instanceof Blob) {
            // no downloading of blob urls in Safari
            var reader = new FileReader();
            reader.onloadend = function () {
                open(reader.result);
            };
            reader.readAsDataURL(data);
        }
        else {
            open(data);
        }
    }
    else {
        let objectUrlCreated = false;
        if (data instanceof Blob) {
            data = URL.createObjectURL(data);
            objectUrlCreated = true;
        }
        if ('download' in a) {
            // download link available
            a.style.display = 'hidden';
            document.body.appendChild(a);
            a.href = data;
            a.download = downloadName;
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
        }
        else {
            openUrl(data);
        }
        if (objectUrlCreated) {
            window.URL.revokeObjectURL(data);
        }
    }
}
export function submit(url, data, callback, onerror) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.addEventListener('load', function () {
        if (xhr.status === 200 || xhr.status === 304) {
            callback(xhr.response);
        }
        else {
            if (typeof onerror === 'function') {
                onerror(xhr.status);
            }
        }
    }, false);
    xhr.send(data);
}
export function open(callback, extensionList = ['*']) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.style.display = 'hidden';
    document.body.appendChild(fileInput);
    fileInput.accept = '.' + extensionList.join(',.');
    fileInput.addEventListener('change', function (e) {
        callback(e.target.files);
    }, false);
    fileInput.click();
}
export function throttle(func, wait, options) {
    // from http://underscorejs.org/docs/underscore.html
    let context;
    let args;
    let result;
    let timeout = null;
    let previous = 0;
    if (!options)
        options = {};
    function later() {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout)
            context = args = null;
    }
    return function throttle() {
        var now = Date.now();
        if (!previous && options.leading === false)
            previous = now;
        var remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout)
                context = args = null;
        }
        else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
}
export function lexicographicCompare(elm1, elm2) {
    if (elm1 < elm2)
        return -1;
    if (elm1 > elm2)
        return 1;
    return 0;
}
/**
 * Does a binary search to get the index of an element in the input array
 * @function
 * @example
 * var array = [ 1, 2, 3, 4, 5, 6 ];
 * var element = 4;
 * binarySearchIndexOf( array, element );  // returns 3
 *
 * @param {Array} array - sorted array
 * @param {Anything} element - element to search for in the array
 * @param {Function} [compareFunction] - compare function
 * @return {Number} the index of the element or -1 if not in the array
 */
export function binarySearchIndexOf(array, element, compareFunction = lexicographicCompare) {
    let low = 0;
    let high = array.length - 1;
    while (low <= high) {
        const mid = (low + high) >> 1;
        const cmp = compareFunction(element, array[mid]);
        if (cmp > 0) {
            low = mid + 1;
        }
        else if (cmp < 0) {
            high = mid - 1;
        }
        else {
            return mid;
        }
    }
    return -low - 1;
}
export function binarySearchForLeftRange(array, leftRange) {
    let high = array.length - 1;
    if (array[high] < leftRange)
        return -1;
    let low = 0;
    while (low <= high) {
        const mid = (low + high) >> 1;
        if (array[mid] >= leftRange) {
            high = mid - 1;
        }
        else {
            low = mid + 1;
        }
    }
    return high + 1;
}
export function binarySearchForRightRange(array, rightRange) {
    if (array[0] > rightRange)
        return -1;
    let low = 0;
    let high = array.length - 1;
    while (low <= high) {
        const mid = (low + high) >> 1;
        if (array[mid] > rightRange) {
            high = mid - 1;
        }
        else {
            low = mid + 1;
        }
    }
    return low - 1;
}
export function rangeInSortedArray(array, min, max) {
    const indexLeft = binarySearchForLeftRange(array, min);
    const indexRight = binarySearchForRightRange(array, max);
    if (indexLeft === -1 || indexRight === -1 || indexLeft > indexRight) {
        return 0;
    }
    else {
        return indexRight - indexLeft + 1;
    }
}
export function dataURItoImage(dataURI) {
    const img = document.createElement('img');
    img.src = dataURI;
    return img;
}
export function uniqueArray(array) {
    return array.sort().filter(function (value, index, sorted) {
        return (index === 0) || (value !== sorted[index - 1]);
    });
}
// String/arraybuffer conversion
export function uint8ToString(u8a) {
    const chunkSize = 0x7000;
    if (u8a.length > chunkSize) {
        const c = [];
        for (let i = 0; i < u8a.length; i += chunkSize) {
            c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + chunkSize)));
        }
        return c.join('');
    }
    else {
        return String.fromCharCode.apply(null, u8a);
    }
}
export function uint8ToLines(u8a, chunkSize = 1024 * 1024 * 10, newline = '\n') {
    let partialLine = '';
    let lines = [];
    for (let i = 0; i < u8a.length; i += chunkSize) {
        const str = uint8ToString(u8a.subarray(i, i + chunkSize));
        const idx = str.lastIndexOf(newline);
        if (idx === -1) {
            partialLine += str;
        }
        else {
            const str2 = partialLine + str.substr(0, idx);
            lines = lines.concat(str2.split(newline));
            if (idx === str.length - newline.length) {
                partialLine = '';
            }
            else {
                partialLine = str.substr(idx + newline.length);
            }
        }
    }
    if (partialLine !== '') {
        lines.push(partialLine);
    }
    return lines;
}
export function getTypedArray(arrayType, arraySize) {
    switch (arrayType) {
        case 'int8':
            return new Int8Array(arraySize);
        case 'int16':
            return new Int16Array(arraySize);
        case 'int32':
            return new Int32Array(arraySize);
        case 'uint8':
            return new Uint8Array(arraySize);
        case 'uint16':
            return new Uint16Array(arraySize);
        case 'uint32':
            return new Uint32Array(arraySize);
        case 'float32':
            return new Float32Array(arraySize);
        default:
            throw new Error('arrayType unknown: ' + arrayType);
    }
}
export function getUintArray(sizeOrArray, maxUint) {
    const TypedArray = maxUint > 65535 ? Uint32Array : Uint16Array;
    return new TypedArray(sizeOrArray);
}
export function ensureArray(value) {
    return Array.isArray(value) ? value : [value];
}
export function ensureBuffer(a) {
    return (a.buffer && a.buffer instanceof ArrayBuffer) ? a.buffer : a;
}
function _ensureClassFromArg(arg, constructor) {
    return arg instanceof constructor ? arg : new constructor(arg);
}
function _ensureClassFromArray(array, constructor) {
    if (array === undefined) {
        array = new constructor();
    }
    else if (Array.isArray(array)) {
        array = new constructor().fromArray(array);
    }
    return array;
}
export function ensureVector2(v) {
    return _ensureClassFromArray(v, Vector2);
}
export function ensureVector3(v) {
    return _ensureClassFromArray(v, Vector3);
}
export function ensureMatrix4(m) {
    return _ensureClassFromArray(m, Matrix4);
}
export function ensureQuaternion(q) {
    return _ensureClassFromArray(q, Quaternion);
}
export function ensureFloat32Array(a) {
    return _ensureClassFromArg(a, Float32Array);
}
export function createRingBuffer(length) {
    let pointer = 0;
    let count = 0;
    const buffer = [];
    return {
        has: function (value) { return buffer.indexOf(value) !== -1; },
        get: function (idx) { return buffer[idx]; },
        push: function (item) {
            buffer[pointer] = item;
            pointer = (length + pointer + 1) % length;
            ++count;
        },
        get count() { return count; },
        get data() { return buffer.slice(0, Math.min(count, length)); },
        clear: function () {
            count = 0;
            pointer = 0;
            buffer.length = 0;
        }
    };
}
export function createSimpleDict() {
    const set = {};
    return {
        has: function (k) { return set[JSON.stringify(k)] !== undefined; },
        add: function (k, v) { set[JSON.stringify(k)] = v; },
        del: function (k) { delete set[JSON.stringify(k)]; },
        get values() { return Object.keys(set).map(k => set[k]); }
    };
}
export function createSimpleSet() {
    const set = {};
    return {
        has: function (v) { return set[JSON.stringify(v)] !== undefined; },
        add: function (v) { set[JSON.stringify(v)] = v; },
        del: function (v) { delete set[JSON.stringify(v)]; },
        get list() { return Object.keys(set).map(k => set[k]); },
    };
}
