/**
 * @file Face Store
 * @author DHJ
 * @private
 */
import Store from './store';
/**
 * Bond store
 */
export default class FaceStore extends Store {
    constructor(size = 0) {
        super(size);
        this.meshCount = 0;
    }
    get _defaultFields() {
        return [
            ['faceOrder', 1, 'int8'],
            ['faceColor', 1, 'int32'],
            ['atomIndex1', 1, 'int32'],
            ['atomIndex2', 1, 'int32'],
            ['atomIndex3', 1, 'int32'],
            ['atomIndex4', 1, 'int32'],
            ['atomIndex5', 1, 'int32'],
            ['atomIndex6', 1, 'int32']
        ];
    }
    addFace(faceOrder, faceColor, atom1, atom2, atom3, atom4, atom5, atom6) {
        this.growIfFull();
        const i = this.count;
        this.faceColor[i] = faceColor;
        this.faceOrder[i] = faceOrder;
        if (atom1) {
            this.atomIndex1[i] = atom1.index;
        }
        if (atom2) {
            this.atomIndex2[i] = atom2.index;
        }
        if (atom3) {
            this.atomIndex3[i] = atom3.index;
        }
        if (atom4) {
            this.atomIndex4[i] = atom4.index;
        }
        if (atom5) {
            this.atomIndex5[i] = atom5.index;
        }
        if (atom6) {
            this.atomIndex6[i] = atom6.index;
        }
        this.meshCount += faceOrder - 2;
        this.count += 1;
    }
}
