/**
 * @file Symmetry Colormaker
 * @author DHJ
 * @private
 */
import { ColormakerRegistry } from '../globals';
import { defaults } from '../utils';
import Colormaker from './colormaker';
const SymmetryColors = [
    0xA800FF,
    0x0079FF,
    0x00F11D,
    0xFFEF00,
    0xFF7F00,
    0xFF0900,
    0x882E72,
    0x1965B0,
    0x4EB265,
    0xFFF7BC,
    0xF6C141,
    0xDC050C,
    0xAE76A3,
    0xEE3377,
    0x5289C7,
    0x90C987,
    0xE8601C,
    0xD1BBD7,
    0x7BAFDE,
    0xCAE0AB,
    0x009988,
    0xBBBBBB //   grey
];
/**
 * Color by atom index. The {@link AtomProxy.index} property is used for coloring.
 * Each {@link ModelProxy} of a {@link Structure} is colored seperately. The
 * `params.domain` parameter is ignored.
 *
 * __Name:__ _atomindex_
 *
 * @example
 * stage.loadFile( "rcsb://1crn" ).then( function( o ){
 *     o.addRepresentation( "ball+stick", { colorScheme: "atomindex" } );
 *     o.autoView();
 * } );
 */
class SymmetryColormaker extends Colormaker {
    constructor(params) {
        params.value = defaults(params.value, SymmetryColors[0]);
        super(params);
    }
    /**
     * get color for an atom
     * @param  {AtomProxy} atom - atom to get color for
     * @return {Integer} hex atom color
     */
    atomColor(atom) {
        return SymmetryColors[atom.symclass];
    }
}
ColormakerRegistry.add('symclass', SymmetryColormaker); // TODO
export default SymmetryColormaker;
